.cur-item-gallery {
  &__bookmark {
    z-index: 10000 !important;
    position: fixed !important;
    top: 5px !important;
    right: 5px !important;
    padding: 19px !important;
    width: 20px !important;
    height: 17px !important;
    box-sizing: border-box;
    background-size: 20px !important;
    background-position: 8px 18px !important;

    &.added {
      background-position: 0 -20px !important;
      top: 23px !important;
      right: -3px !important;
    }
  }

  &__calculator {
    z-index: 10000 !important;
    position: fixed !important;
    top: 60px !important;
    right: 15px !important;

    &--animate-opacity {
      opacity: 0.0001;
      will-change: opacity;
      transition: opacity 700ms cubic-bezier(0.4, 0, 0.22, 1);
    }
  }

  &__modal {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    z-index: 10000;
    width: 100%;
    font-family: $helvetica;

    &-header {
      padding: 0 15px;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      height: 60px;
      top: 0;
      right: 0;
    }

    &-footer {
      position: fixed;
      bottom: 0;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      gap: 10px;

      &--animate-opacity {
        opacity: 0.0001;
        will-change: opacity;
        transition: opacity 700ms cubic-bezier(0.4, 0, 0.22, 1);
      }

      &-info {
        color: #fff;
        font-size: 12px;

        &-title {
          font-size: 18px;
          font-weight: 500;
        }

        &-desc {
          font-size: 12px;
        }

        span {
          display: block;
        }
      }
    }
  }

  &__info-container {
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 10px;
    padding: 0 15px;
    height: 25px;
    width: 100%;
    z-index: 1;

    &-icons {
      background-color: #fff;
      border-radius: 7px;

      i {
        svg {
          margin: 6px 5px;
        }
      }
    }

    &-counter {
      color: #fff;
      align-self: center;
      padding: 5px 15px;
      font-family: $helvetica;
      font-size: 12px;
      border-radius: 7px;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  &__thumbnail {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 330px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;

    &-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &--hidden {
    display: none;
  }

  &__pswp {
    .pswp {
      &--item {
        .pswp__preloader {
          position: fixed;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &__phone-btn {
        position: fixed;
        bottom: 15px;
        right: 15px;
        width: 50px;
        height: 50px;
        background-image: url('/static/images/phone_call_white-icon.svg');
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: #3db460;
      }

      &__description {
        position: fixed;
        bottom: 15px;
        left: 15px;

        &.top {
          bottom: 70px;
        }
      }

      &__kapitalbank-calc {
        position: fixed;
        bottom: 15px;
        left: 15px;
      }

      &__button {
        &--close {
          position: absolute !important;
          left: 0;
          top: 10px;
        }
      }

      &__counter {
        transform: translate(-50%, 0);
        position: absolute;
        left: 50%;
        top: 10px;
        margin-inline-start: 0 !important;
        font-size: 16px;
        font-weight: 500;
        opacity: 1 !important;
      }

      &__top-bar {
        height: 60px !important;
      }
    }
  }
}

.webview {
  &.safe-area-top {
    .cur-item-gallery {
      &__pswp {
        .pswp__top-bar {
          background-color: transparent;
          z-index: 2;
          top: max(env(safe-area-inset-top), 40px);
        }

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: calc(60px + max(env(safe-area-inset-top), 40px));
          position: absolute;
          top: 0;
          background-color: #000;
          left: 0;
          right: 0;
          z-index: 1;
        }

        &::after {
          content: '';
          display: block;
          width: 100%;
          height: calc(60px + max(env(safe-area-inset-top), 40px) + 28px);
          position: absolute;
          bottom: 0;
          background-color: #000;
          left: 0;
          right: 0;
          z-index: 1;
        }

        .pswp__description,
        .pswp__phone-btn {
          z-index: 2;
        }

        .pswp__kapitalbank-calc {
          z-index: 2;
          bottom: env(safe-area-inset-bottom, 0);
        }
      }

      &__bookmark {
        margin-top: max(env(safe-area-inset-top), 40px) !important;
      }
    }
  }

  .cur-item-gallery__pswp {
    .pswp__description {
      bottom: calc(env(safe-area-inset-bottom, 0) + 60px);
    }

    .pswp__phone-btn {
      bottom: env(safe-area-inset-bottom, 0);
    }
  }
}
