.app-bar-webview {
  --scroll-progress: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  background-color: rgba(255, 255, 255, var(--scroll-progress));
  position: fixed;
  top: 0;
  z-index: 95;
  width: 100%;
  height: calc(60px + max(env(safe-area-inset-top), 40px));
  padding: 0 15px 12.5px;

  &--actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  &__button {
    width: 35px;
    height: 35px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 17.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &-back {
      position: relative;
      height: 16px;
      width: 10px;

      svg {
        transform: none;
      }
    }

    .bookmarking {
      top: 50%;
      left: 50%;
      right: 0;
      transform: translate(-50%, -50%);

      &.webview-favorite-buttons {
        background-image: url('/static/images/webview/icon-favorite.svg');
      }
    }
  }
}
